.lws-feed-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    li{
        max-width: 33.3%;
        flex: 0 0 33.3%;
        padding: 15px;
        @media screen and(max-width:1199px){
            max-width: 50%;
            flex: 0 0 50%; 
        }
        @media screen and(max-width:767px){
            max-width: 100%;
            flex: 0 0 100%; 
        }
        &::after{
            display: none;
        }
    }
    .feed-item {
        text-align: left;
        border: 1px solid #dedede;
        background-color: #fff;
        margin-bottom: 15px;
        

        &.shared {
            padding-left: 1.25rem;
            border: none;
            border-left: 2px solid #f0f0f0;
            margin: 0 1.25rem;
        }

        &-header {
            padding: 1rem 1.25rem 0;
            display: flex;
            &-avatar {
                display: block;
                border-radius: 50%;
                margin-right: 1rem;
            }
            &-info {
                display: flex;
                flex-direction: column;
                &-name {
                    font-weight: 600;
                    color: #0d0b09;
                }
                &-date {
                    font-size: .65rem;
                    color: rgba(0,0,0,.8);
                }
            }
        }
        &-body {
            .feed-item-shared {
                padding: 1rem 1.25rem;
                font-size: .9rem;
            }
        }
        &-message {
            padding: 1rem 1.25rem;
            font-size: .9rem;

              max-height: 170px;
            position: relative;
            overflow: hidden;

            .read-more { 
                position: absolute; 
                bottom: -0; 
                left: 0;
                width: 100%; 
                color: #E32017;
                text-align: center; 
                margin: 0; padding: 30px 1.25rem;
                background-image: linear-gradient(to bottom, transparent, #fff);
                
                a.btn{
                    background-color: #E32017;
                    color: #fff;
                    padding: 5px 10px;
                    &:hover{
                        text-decoration: none;
                        background-color: darken(#E32017,20%);
                    }
                }
              }
        }
        &-photo {
            background-color: #f4f4f4;
            padding: 10px 0 0;
            text-align: center;
            
            a{
                display: block;
                
            }
            img{
                max-width: 100%;
                height: auto;
            }
            &-gallery {
                display: flex;
                justify-content: center;
                padding: 10px;
                border-top: 1px solid #ccc;
                margin-top: 10px;
                &-item {
                    margin: 0; padding: 0;  
                    width: 100%;
                    height: 280px;
                    overflow: hidden;
                    img {
                        width: 100%; height: 100%;
                        object-fit: cover;
                    }
                    &:not(:first-child){
                        display: none;
                    }
                }
            }
        }
        &-footer {
            display: flex;
            padding: 1rem 1.25rem;
            font-size: .85rem;
            align-items: center;
            &-likes{
                i{
                    color: #E32017;
                }
            }
            &-comments{ 
                margin-left: auto; 
            }
            &-shares{
                margin-left: 15px;
            }
        }
    }
}